import axios from 'axios'
import { ProductCode } from '../../common/product-variables'

const performanceApiUrl = process.env.REACT_APP_PERFORMANCE_API_URL!

// If Performance API has already migrated the codes we map our codes from TEL to APEX
const performanceApiProductCodeMigrated =
  process.env.REACT_APP_PRODUCT_CODES_MIRATED_IN_PERFORMANCE_API === 'true'

const MAP_PRODUCT_CODE_TEL_TO_APEX: { [key: string]: string } = {
  ...(performanceApiProductCodeMigrated
    ? {
        [ProductCode.FFMF]: ProductCode.FFMF,
        // [ProductCode.FF_PREMIUM]: ProductCodeApex.FF_PREMIUM, // We can uncomment this when Snowflake uses Apex fund codes for Premuim Service
        // [ProductCode.FF_INV]: ProductCodeApex.FF_INV, // We can uncomment this when Snowflake uses Apex fund codes for Investment Series
      }
    : {}),
}

export const getProductReturnsSummary = async (productCode: ProductCode) => {
  let response
  const code = MAP_PRODUCT_CODE_TEL_TO_APEX[productCode] || productCode
  try {
    response = await axios.get<any>(
      `${performanceApiUrl}/returns-summary-tables`
    )
    return (response.data?.data || []).find(
      (item: { productCode: ProductCode }) => item.productCode === code
    )
  } catch (err) {
    console.log('Error:', err)
  }
}
