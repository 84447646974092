import { useState } from 'react'
import { RadioMenu, Button, Link } from 'shared'
import { useMedia } from '../../hooks/use-media'
import CustomIcon from '../../common/CustomIcon'
import { getProductWithdrawalFormUrl } from '../../common/product-helper'
import { Account } from '../../redux/accounts/accounts.model'

type WithdrawFundsIntroProps = {
  account: Account
  onSubmit: () => void
}
const WithdrawFundsIntro = ({ account, onSubmit }: WithdrawFundsIntroProps) => {
  const [isFirstWithdrawal, setIsFirstWithdrawal] = useState<boolean>(null)
  const { isTabletLandscape } = useMedia()

  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  return (
    <div>
      <p className="text-bold color-midnight">
        Is this your first time making a retirement withdrawal from your
        account?
      </p>
      <RadioMenu
        onChange={(value) => {
          setIsFirstWithdrawal(value?.value)
        }}
        value={options.find((o) => o.value === isFirstWithdrawal)}
        options={options}
        name={'firstWithdrawal'}
        direction={'horizontal'}
      />
      {isFirstWithdrawal && (
        <p>
          To make a withdrawal, please download and complete our withdrawal form
          below and email it to{' '}
          <a href="mailto:enquiries@fisherfunds.co.nz">
            enquiries@fisherfunds.co.nz
          </a>
          .
        </p>
      )}
      <div className="flex-row-justify-content-end my-sm">
        {isFirstWithdrawal ? (
          <Link
            iconLeft={() => <CustomIcon icon="download" />}
            target="_blank"
            variant="filled"
            size={isTabletLandscape ? 'md' : 'sm'}
            to={getProductWithdrawalFormUrl(account)}
          >
            Download form
          </Link>
        ) : (
          <Button
            className="text-regular"
            type="submit"
            variant="filled"
            color="primary"
            disabled={isFirstWithdrawal === null}
            size={isTabletLandscape ? 'md' : 'sm'}
            onClick={onSubmit}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  )
}

export default WithdrawFundsIntro
