export enum actionTypes {
  SET_NUM_UNSEEN_ATOMIC_CARDS_REQUEST = 'SET_NUM_UNSEEN_ATOMIC_CARDS_REQUEST',
  SET_IS_ATOMIC_INITIALISED_REQUEST = 'SET_IS_ATOMIC_INITIALISED_REQUEST',
  ATOMIC_JWT_REQUEST = 'ATOMIC_JWT_REQUEST',
  ATOMIC_JWT_REQUEST_SUCCESS = 'ATOMIC_JWT_REQUEST_SUCCESS',
  ATOMIC_JWT_REQUEST_FAILURE = 'ATOMIC_JWT_REQUEST_FAILURE',
}

export type SetNumUnseenAtomicCardsRequestActionType = {
  type: actionTypes.SET_NUM_UNSEEN_ATOMIC_CARDS_REQUEST
  payload: number
}
export type SetIsAtomicInitialisedRequestActionType = {
  type: actionTypes.SET_IS_ATOMIC_INITIALISED_REQUEST
  payload: boolean
}

export type AtomicJwtRequestActionType = {
  type: actionTypes.ATOMIC_JWT_REQUEST
  payload: { fscClientId: string }
}
export type AtomicJwtRequestSuccessActionType = {
  type: actionTypes.ATOMIC_JWT_REQUEST_SUCCESS
  payload: string
}
export type AtomicJwtRequestGetFailureActionType = {
  type: actionTypes.ATOMIC_JWT_REQUEST_FAILURE
  payload: string
}

export const SetNumUnseenAtomicCardsRequestAction = (
  numUnseenAtomicCards: number
): SetNumUnseenAtomicCardsRequestActionType => ({
  type: actionTypes.SET_NUM_UNSEEN_ATOMIC_CARDS_REQUEST,
  payload: numUnseenAtomicCards,
})

export const SetIsAtomicInitialisedRequestAction = (
  isAtomicInitialised: boolean
): SetIsAtomicInitialisedRequestActionType => ({
  type: actionTypes.SET_IS_ATOMIC_INITIALISED_REQUEST,
  payload: isAtomicInitialised,
})

export const AtomicJwtRequestAction = (
  fscClientId: string
): AtomicJwtRequestActionType => ({
  type: actionTypes.ATOMIC_JWT_REQUEST,
  payload: {
    fscClientId: fscClientId,
  },
})

export const AtomicJwtRequestSuccessAction = (
  jwt: string
): AtomicJwtRequestSuccessActionType => ({
  type: actionTypes.ATOMIC_JWT_REQUEST_SUCCESS,
  payload: jwt,
})

export const AtomicJwtRequestFailureAction = (
  message: string
): AtomicJwtRequestGetFailureActionType => ({
  type: actionTypes.ATOMIC_JWT_REQUEST_FAILURE,
  payload: message,
})

export type AtomicNotificationsActions =
  | SetNumUnseenAtomicCardsRequestActionType
  | SetIsAtomicInitialisedRequestActionType
  | AtomicJwtRequestActionType
  | AtomicJwtRequestSuccessActionType
  | AtomicJwtRequestGetFailureActionType
