import AtomicSDK, {
  AACStreamContainer,
  SDKConfiguration,
} from '@atomic.io/action-cards-web-sdk'
import React, { useCallback, useRef } from 'react'
import { formatCurrency } from '../common/currency-helper'
import { Account } from '../redux/accounts/accounts.model'

const ATOMIC_API_HOST = process.env.REACT_APP_ATOMIC_API_HOST
const ATOMIC_API_KEY = process.env.REACT_APP_ATOMIC_API_KEY
const ATOMIC_ENVIRONMENT_ID = process.env.REACT_APP_ATOMIC_ENVIRONMENT_ID
const ATOMIC_STREAM_CONTAINER_ID =
  process.env.REACT_APP_ATOMIC_STREAM_CONTAINER_ID

const getAtomicConfig = (totalBalance: number, accountErrors: Account[]) =>
  ({
    streamContainerId: ATOMIC_STREAM_CONTAINER_ID,
    enabledUiElements: {
      cardListHeader: false,
    },
    onRuntimeVariablesRequested: (cards, callback) => {
      cards.forEach(function (card) {
        card.runtimeVariables.set(
          'TotalBalance',
          !accountErrors?.length
            ? formatCurrency(totalBalance, '$0,0.00')
            : '--.--'
        )
      })
      callback(cards)
    },
  } as SDKConfiguration)

export function useAtomic(totalBalance: number, accountErrors: Account[]) {
  const streamContainerRef = useRef<AACStreamContainer>()
  const initAtomicEmbed = useCallback(
    (el: HTMLDivElement) => {
      if (streamContainerRef.current) {
        streamContainerRef.current.stop()
      }
      if (el) {
        const config = getAtomicConfig(totalBalance, accountErrors)
        streamContainerRef.current = AtomicSDK.embed(el, config)
      }
    },
    [totalBalance, accountErrors]
  )

  return [<div ref={initAtomicEmbed} className="atomic-embed-wrapper" />]
}

export const updateAtomicUnseenCards = (
  setUnseenCards: (unseenCards: number) => void
) => {
  AtomicSDK.requestUserMetrics().then((resr) => {
    const cardNum = resr.unseenCardsForStreamContainer(
      ATOMIC_STREAM_CONTAINER_ID
    )
    setUnseenCards(Math.max(cardNum, 0))
  })
}

export const initialiseAtomic = async (
  atomicJwt: string,
  setUnseenCards: (unseenCards: number) => void
) => {
  await AtomicSDK.initialise(
    ATOMIC_API_HOST,
    ATOMIC_API_KEY,
    ATOMIC_ENVIRONMENT_ID
  )
  AtomicSDK.setSessionDelegate(async () => atomicJwt)
  updateAtomicUnseenCards(setUnseenCards)
}
