import { useEffect, useMemo } from 'react'
import cn from 'classnames'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CheckAuthorisation from '../../components/check-authorisation/CheckAuthorisation'
import MuiTheme from '../../common/MuiTheme'
import NavigationDesktop from '../../components/navigation-desktop'
import NavigationMobileContainer from '../../components/navigation-mobile/NavigationMobile.container'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import sha256 from 'crypto-js/sha256'
import { connect } from 'react-redux'
import { AppState } from '../../redux/app-state'
import { bindActionCreators, Dispatch } from 'redux'
import { AccountsOverviewRequestAction } from '../../redux/accounts/accounts.actions'
import { AuthorisationRequestSuccessAction } from '../../redux/authorisation/authorisation.actions'
import {
  UserAuthorisationSuccessAction,
  UserRequestDetailsAction,
} from '../../redux/user/user.actions'
import PageRedirecting from '../page-redirecting/PageRedirecting'
import PageLoggedOut from '../page-logged-out/PageLoggedOut'
import { setDatadogUser } from '../../initDatadog'
import { trackUserId } from '../../common/gtm-helper'
import FFMToast from '../../components/ffm-toast/FFMToast'
import ContactUsModal from '../../components/contact-us-modal/ContactUsModal'
import { HASH_MODALS, hashIncludes, removeHash } from '../../common/hash-helper'
import AccountsHeader from '../../components/accounts-header/AccountsHeader'
import './DashboardTheme.scss'
import { initialiseAtomic } from '../../hooks/useAtomic'
import {
  AtomicJwtRequestAction,
  SetIsAtomicInitialisedRequestAction,
  SetNumUnseenAtomicCardsRequestAction,
} from '../../redux/atomic-notifications/atomic-notifications.actions'

const ENABLE_ATOMIC = process.env.REACT_APP_FEATURE_TOGGLE_ATOMIC === 'true'

export const MainContent = (props: any) => {
  const {
    isLoadingAccounts,
    setAuthToken,
    setUserId,
    authToken,
    userid,
    setUserDetails,
    activeMenu,
    location,
    getAccountsOverview,
    authenticationFailed,
    getAtomicJwt,
    atomicJwt,
    setIsAtomicInitialised,
    isAtomicInitialised,
    setUnseenCards,
  } = props
  const {
    user,
    isLoading: isAuthLoading,
    error,
    getAccessTokenSilently,
  } = useAuth0()
  const showContactUsModal = useMemo(
    () => hashIncludes(HASH_MODALS.CONTACT_US),
    [location?.hash] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    ;(async () => {
      const token = await getAccessTokenSilently()
      setAuthToken({
        authorisationToken: token,
        lastAuthenticationTime: '',
        filesToken: '',
      })
      const userId =
        user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/userSessionId`]
      setUserId(userId)
      setUserDetails(userId)

      const hashedEmail = sha256(user.email).toString()
      setDatadogUser({ id: hashedEmail })
      trackUserId(hashedEmail)
      if (ENABLE_ATOMIC && !atomicJwt) {
        getAtomicJwt(user.userid)
      }
    })()
  }, [setUserId, setAuthToken, user, setUserDetails, getAccessTokenSilently]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userid) {
      getAccountsOverview(userid)
    }
  }, [userid, getAccountsOverview]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isAtomicInitialised && !!atomicJwt) {
      initialiseAtomic(atomicJwt, setUnseenCards)
      setIsAtomicInitialised(true)
    }
  }, [atomicJwt]) // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (authenticationFailed) {
    return <PageLoggedOut isTimeout={true} />
  }

  if (isAuthLoading || isLoadingAccounts || !(authToken && userid)) {
    return <PageRedirecting />
  }

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <CheckAuthorisation />

      <div
        className={cn('dashboard-theme-container', {
          'show-header-desktop': props.showHeader,
        })}
      >
        <NavigationDesktop />

        <main className={`main-content ${activeMenu}-content-layout`}>
          <AccountsHeader />
          {props.children}
          <FFMToast />
          <NavigationMobileContainer activeMenu={activeMenu} />
        </main>

        <ContactUsModal open={showContactUsModal} onClose={removeHash} />
      </div>
    </MuiThemeProvider>
  )
}

const mapStateToProps = (state: AppState) => ({
  authToken: state.authorisation.authorisationToken,
  userid: state.user.userid,
  authenticationFailed:
    state.authorisation.isLoggedOut && state.authorisation.hasError,
  showSidebar: state.layout.showSidebar,
  activeMenu: state.layout.activeMenu,
  showHeader: state.layout.showHeader,
  isLoadingAccounts: state.accounts.isLoading,
  atomicJwt: state.atomicNotifications.atomicJwt,
  isAtomicInitialised: state.atomicNotifications.isAtomicInitialised,
})

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  setUserId: bindActionCreators(UserAuthorisationSuccessAction, dispatch),
  setAuthToken: bindActionCreators(AuthorisationRequestSuccessAction, dispatch),
  setUserDetails: bindActionCreators(UserRequestDetailsAction, dispatch),
  getAccountsOverview: bindActionCreators(
    AccountsOverviewRequestAction,
    dispatch
  ),
  getAtomicJwt: bindActionCreators(AtomicJwtRequestAction, dispatch),
  setIsAtomicInitialised: bindActionCreators(
    SetIsAtomicInitialisedRequestAction,
    dispatch
  ),
  setUnseenCards: bindActionCreators(
    SetNumUnseenAtomicCardsRequestAction,
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withAuthenticationRequired(MainContent, {
    // Hash is lost after a successful login so we save it into returnTo
    returnTo: `${window.location.pathname}${window.location.hash}`,
    onRedirecting: () => <PageRedirecting />,
  })
)
