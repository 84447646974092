import { APEX_FUNDS_CODES } from 'shared'
import { Account } from '../redux/accounts/accounts.model'
import { isOverRetirementAge } from './user-helper'
import { UserState } from '../redux/user/user.model'
import {
  FUTUREPLAN,
  INVESTMENT_FUNDS,
  INVESTMENT_SERIES_APEX,
  INVESTMENT_SERIES_TEL,
  KIWISAVER_ONE,
  KIWISAVER_PLAN,
  KIWISAVER_TWO,
  LIFE_SAVER,
  MANAGED_FUNDS_TEL,
  MANAGED_FUNDS_APEX,
  PREMIUM_SERVICE_APEX,
  PREMIUM_SERVICE_TEL,
  ProductDisplayOrder,
  ProductSlug,
} from './product-variables'

const externalRefMap = {
  kiwiSaver: [KIWISAVER_ONE, KIWISAVER_PLAN, KIWISAVER_TWO],
  managedFunds: [
    MANAGED_FUNDS_TEL,
    MANAGED_FUNDS_APEX,
    INVESTMENT_SERIES_TEL,
    INVESTMENT_SERIES_APEX,
    PREMIUM_SERVICE_TEL,
    PREMIUM_SERVICE_APEX,
    LIFE_SAVER,
    FUTUREPLAN,
  ],
}

export const hasOwnKiwiSaverAccount = (
  accounts: Account[],
  needsToBeOwner: boolean = true
): boolean => {
  let hasOwnKiwiSaver = false
  if (accounts) {
    hasOwnKiwiSaver = accounts.some((account) =>
      isKiwisaverAccount(account, needsToBeOwner)
    )
  }
  return hasOwnKiwiSaver
}
export const hasKiwiSaverOneAccount = (accounts: Account[]): boolean => {
  let hasOwnKiwiSaver = false
  if (accounts) {
    hasOwnKiwiSaver = accounts.some((account) =>
      isKiwiSaverOneAccount(account, true)
    )
  }
  return hasOwnKiwiSaver
}

export const hasAccountThatWillRebalance = (accounts: Account[]): boolean =>
  accounts && accounts.some(({ rebalance }) => rebalance)

export const PRODUCTS_WITH_RETIREMENT_PROJECTOR = (account: Account) =>
  [
    process.env.REACT_APP_FEATURE_TOGGLE_FFKP_RETIREMENT_PROJECTOR !==
      'false' && KIWISAVER_PLAN,
    KIWISAVER_TWO,
  ]
    .filter((ProductCode) => !!ProductCode)
    .includes(account?.productExternalRef)

export const getRetirementProjectorEligibleKiwisaverAccount = (
  accounts: Account[]
): Account =>
  accounts &&
  accounts.find((account) => PRODUCTS_WITH_RETIREMENT_PROJECTOR(account))

export const getKiwisaverAccount = (accounts: Account[]): Account =>
  accounts && accounts.find((account) => isKiwisaverAccount(account, true))

export const isKiwisaverAccount = (
  account: Account,
  needsToBeOwner: boolean
): boolean => {
  return (
    (account.isOwner || !needsToBeOwner) &&
    (externalRefMap.kiwiSaver.includes(account.productExternalRef) ||
      /kiwisaver/i.test(account.productDisplayName))
  )
}

export const isKiwiSaverOneAccount = (
  account: Account,
  needsToBeOwner: boolean
): boolean => {
  return (
    (account.isOwner || !needsToBeOwner) &&
    account.productExternalRef === KIWISAVER_ONE
  )
}

export const isKiwiSaverTwoAccount = (
  account: Account,
  needsToBeOwner: boolean
): boolean => {
  return (
    (account.isOwner || !needsToBeOwner) &&
    account.productExternalRef === KIWISAVER_TWO
  )
}

export const isOwnedManagedFundsAccount = (account: Account): boolean => {
  return account.isOwner && isManagedFundsAccount(account)
}

export const hasManagedFundsAccount = (accounts: Account[]): boolean =>
  accounts && accounts.some((account) => isManagedFundsAccount(account))

export const hasInvestmentFundsAccount = (accounts: Account[]): boolean =>
  accounts && accounts.some((account) => isInvestmentFundsAccount(account))

export const hasOwnAccount = (accounts: Account[]): boolean =>
  accounts && accounts.some(({ isOwner }) => isOwner)

export const hasCompanyAccount = (accounts: Account[]): boolean =>
  accounts && accounts.some(({ isOwner }) => !isOwner)

const isManagedFundsAccount = ({ productExternalRef }: Account): boolean =>
  externalRefMap.managedFunds.includes(productExternalRef)

export const isManagedFundProductAccount = ({
  productExternalRef,
}: Account): boolean =>
  [MANAGED_FUNDS_TEL, MANAGED_FUNDS_APEX].includes(productExternalRef)

export const isLifeSaverAccount = ({ productName }: Account): boolean => {
  return productName.toLowerCase().includes('lifesaver')
}

export const isFuturePlanAccount = ({ productName }: Account): boolean => {
  return productName.toLowerCase().includes('futureplan')
}

export const isInvestmentSeries = ({ productName }: Account): boolean => {
  return productName.toLowerCase().includes('investment series')
}

export const isPremiumServiceAccount = ({
  productExternalRef,
}: Account): boolean =>
  [PREMIUM_SERVICE_APEX, PREMIUM_SERVICE_TEL].includes(productExternalRef)

export const hasOwnKiwiSaverPlanAccount = (accounts: Account[]) =>
  accounts &&
  accounts.some((account) => account.isOwner && isKiwiSaverPlanAccount(account))

export const isInvestmentFundsAccount = ({ productExternalRef }: Account) => {
  return productExternalRef === INVESTMENT_FUNDS
}
export const isKiwiSaverPlanAccount = ({ productExternalRef }: Account) => {
  return productExternalRef === KIWISAVER_PLAN
}

export const getOwnKiwiSaverPlanAccount = (accounts: Account[]): Account =>
  accounts &&
  accounts.find((account) => account.isOwner && isKiwiSaverPlanAccount(account))

export const hasApexProductAccount = (accounts: Account[]) =>
  accounts &&
  accounts.some((account) => isApexProductAccount(account.productExternalRef))

export const isApexProductAccount = (productExternalRef: string) =>
  [
    KIWISAVER_PLAN,
    INVESTMENT_FUNDS,
    KIWISAVER_TWO,
    MANAGED_FUNDS_APEX,
    PREMIUM_SERVICE_APEX,
    INVESTMENT_SERIES_APEX,
  ].includes(productExternalRef)

/**
 * This is only used for AccountDetailsHeader and AccountCard and it only
 * identifies a few product types and for the rest it returns "default"
 * If you need something like this but for all product types use the one in product-helper
 * */

export const getAccountProductClass = (account: Account): string => {
  if (isKiwisaverAccount(account, false)) {
    return ProductSlug.FFKS
  }
  if (isManagedFundProductAccount(account)) {
    return ProductSlug.FFMF
  }
  if (isPremiumServiceAccount(account)) {
    return ProductSlug.FF_PREMIUM
  }
  return 'default'
}

export const getAccountByID = (
  accountID: string,
  accounts: { accountID: string }[]
): any => {
  return accounts.find((account) => account.accountID === accountID)
}

export const hasWriteAuthorisation = (account: Account): boolean => {
  return account.isAuthRep || account.isOwner || account.canWrite
}

const isFFKPRetirementProjectorEnabled =
  process.env.REACT_APP_FEATURE_TOGGLE_FFKP_RETIREMENT_PROJECTOR === 'true'

export const canDisplayRetirementTool = (
  accounts: Account[] = [],
  user: UserState
) => {
  const fundedAccounts = accounts.filter((account) => !account.isDepositMode)
  return (
    !isOverRetirementAge(user.birthDate) &&
    (fundedAccounts.some(
      (a) => isKiwiSaverOneAccount(a, true) || isKiwiSaverTwoAccount(a, true)
    ) ||
      fundedAccounts.some(
        (a) => isKiwiSaverPlanAccount(a) && isFFKPRetirementProjectorEnabled
      ))
  )
}

export const canDisplayFirstHomeBuyerTools = (accounts: Account[]) =>
  hasOwnKiwiSaverAccount(accounts, false) &&
  !hasOwnKiwiSaverPlanAccount(accounts)

export const getFirstHomeCalculatorUrl = ({
  productExternalRef,
}: Account): string =>
  `https://firsthome.fisherfunds.co.nz/?type=${productExternalRef}`

export const isMixMandate = (account: Account) =>
  (account?.funds || []).length > 1

export const isCashPlusLegacy = (account: Account) =>
  !isMixMandate(account) &&
  (account?.funds || [])[0]?.assetCode === APEX_FUNDS_CODES.FFKP_CASH_PLUS

export const getSimpleProductName = (accountExternalRef: String) => {
  switch (accountExternalRef) {
    case KIWISAVER_ONE:
      return 'KiwiSaver Scheme'
    case KIWISAVER_TWO:
      return 'KiwiSaver TWO'
    case KIWISAVER_PLAN:
      return 'KiwiSaver Plan'
    case FUTUREPLAN:
      return 'FuturePlan'
    case LIFE_SAVER:
      return 'LifeSaver'
    case INVESTMENT_SERIES_TEL: // TODO: Once product has migrated to Apex, delete TEL reference.
    case INVESTMENT_SERIES_APEX:
      return 'Investment Series'
    case INVESTMENT_FUNDS:
      return 'Investment Funds'
    case MANAGED_FUNDS_TEL: // TODO: Once product has migrated to Apex, delete TEL reference.
    case MANAGED_FUNDS_APEX:
      return 'Managed Funds'
    case PREMIUM_SERVICE_TEL: // TODO: Once product migrated to Apex, delete TEL reference.
    case PREMIUM_SERVICE_APEX:
      return 'Premium'
    default:
      return ''
  }
}

export const orderAccountsByProduct = (accounts: Account[]): Account[] =>
  accounts.sort(
    (a, b) =>
      ProductDisplayOrder.indexOf(a.productExternalRef) -
      ProductDisplayOrder.indexOf(b.productExternalRef)
  )

type AccountTotalsResult = {
  total: number
  allAccounts: Account[]
}

export const calculateAccountTotal = (
  accounts: Account[]
): AccountTotalsResult => {
  const ownAccounts: Account[] = []
  const relatedAccounts: Account[] = []

  if (accounts) {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].isOwner) {
        ownAccounts.push(accounts[i])
      } else {
        relatedAccounts.push(accounts[i])
      }
    }
  }

  // combining all account
  const allAccounts = [...ownAccounts, ...relatedAccounts]

  // Find the totals for own and related accounts
  const totalReducer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue
  const ownAccountsTotal = ownAccounts
    .map((account) => account.accountTotal)
    .reduce(totalReducer, 0)
  const relatedAccountsTotal = relatedAccounts
    .map((account) => account.accountTotal)
    .reduce(totalReducer, 0)

  const result: AccountTotalsResult = {
    total: ownAccountsTotal + relatedAccountsTotal,
    allAccounts: allAccounts,
  }

  return result
}
