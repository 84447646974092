import {
  Fund,
  Strategy,
} from '../components/switch-contentful-modal/SwitchContentfulModal.types'
import { Account } from '../redux/accounts/accounts.model'
import {
  isFuturePlanAccount,
  isInvestmentFundsAccount,
  isInvestmentSeries,
  isKiwiSaverOneAccount,
  isKiwiSaverTwoAccount,
  isKiwisaverAccount,
  isKiwiSaverPlanAccount,
  isLifeSaverAccount,
  isManagedFundProductAccount,
  isOwnedManagedFundsAccount,
  isPremiumServiceAccount,
} from './accounts-helper'
import { RichText } from './contentful.model'
import {
  FUTUREPLAN,
  INVESTMENT_FUNDS,
  KIWISAVER_ONE,
  KIWISAVER_PLAN,
  LIFE_SAVER,
  ProductCode,
  ProductSlug,
  ProductType,
  MANAGED_FUNDS_TEL,
  PREMIUM_SERVICE_TEL,
  INVESTMENT_SERIES_TEL,
  MANAGED_FUNDS_APEX,
  PREMIUM_SERVICE_APEX,
  INVESTMENT_SERIES_APEX,
  KIWISAVER_TWO,
} from './product-variables'

export interface ProductDisclosureStatement {
  name: string
  file: {
    description: string
    name: string
    file: {
      filename: string
      url: string
    }
  }
}

export interface InfoPoint {
  shortName: string
  icon: string
  description?: RichText
  link?: string
  linkText?: string
}

export type DocumentGroup = {
  name: string
  slug: string
  documentsOrDocumentGroups: (Document | DocumentGroup)[]
}

export interface Product {
  id: string
  slug: ProductSlug
  name: string
  description?: string
  salesforceCode: ProductType
  strategies: Strategy[]
  funds: Fund[]
  productDisclosureStatement: ProductDisclosureStatement
  termsAndConditions: RichText
  infoPoints?: InfoPoint[]
  documents?: DocumentGroup[]
  isSuspended?: boolean
  productCode: ProductCode
}

export interface BankDepositDetials {
  bankAccount: string
  debitLink: string
}

export const PRODUCT_DEPOSIT_FUNDS_URL = {
  [ProductSlug.FF_LIFE]:
    'https://fisherfunds.co.nz/assets/Forms/FF672-LifeSaver-Direct-Debit-Authority.pdf',
  [ProductSlug.FF_FUTURE]:
    'https://fisherfunds.co.nz/assets/Forms/FF639-FreedomPlan-FuturePlan-Direct-Debit-Authority.pdf',
  [ProductSlug.FF_INV]:
    'https://fisherfunds.co.nz/assets/Forms/FF647-Investment-Series-Direct-Debit-Authority.pdf',
}

export const PRODUCT_PDS_FILES = {
  [ProductSlug.FFKS]:
    'https://assets.fisherfunds.co.nz/fisher-funds-kiwisaver-scheme-product-disclosure-statement',
  [ProductSlug.FF_TWO]:
    'https://assets.fisherfunds.co.nz/fisher-funds-two-kiwisaver-scheme-product-disclosure-statement',
  [ProductSlug.FFMF]:
    'https://assets.fisherfunds.co.nz/fisher-funds-managed-funds-pds',
  [ProductSlug.FFIF]:
    'https://assets.fisherfunds.co.nz/investment-funds-product-disclosure-statement',
  [ProductSlug.FFKP]:
    'https://assets.fisherfunds.co.nz/kiwisaver-plan-product-disclosure-statement',
  [ProductSlug.FF_LIFE]: 'https://assets.fisherfunds.co.nz/lifesaver-plan-pds',
  [ProductSlug.FF_INV]:
    'https://assets.fisherfunds.co.nz/investment-series-pds',
}

const PRODUCT_SWITCH_REQUEST_URL = {
  [ProductSlug.FF_LIFE]:
    'https://assets.fisherfunds.co.nz/lifesaver-plan-ff625-lifesaver-investment-switch-form',
  [ProductSlug.FF_FUTURE]:
    'https://assets.fisherfunds.co.nz/futureplan-investment-switch-form',
  [ProductSlug.FF_INV]:
    'https://fisherfunds.co.nz/assets/Forms/FF632-Investment-Series-Investment-Switch-Form.pdf',
  [ProductSlug.FFKP]: PRODUCT_PDS_FILES[ProductSlug.FFKP],
  [ProductSlug.FFIF]:
    'https://assets.fisherfunds.co.nz/investment-funds-changing-investments-form',
}

const PRODUCT_WITHDRAWAL_FORM_URL = {
  [ProductSlug.FFKP]:
    'https://assets.fisherfunds.co.nz/kiwisaver-plan-retirement-withdrawal-form',
  [ProductSlug.FF_TWO]:
    'https://assets.fisherfunds.co.nz/kiwisaver-retirement-withdrawal-form',
  [ProductSlug.FFIF]:
    'https://assets.fisherfunds.co.nz/investment-funds-withdrawals-form',
}

export const getProductWithdrawalFormUrl = (account: Account) => {
  const productSlug = getAccountProductSlug(account)
  return PRODUCT_WITHDRAWAL_FORM_URL[
    productSlug as keyof typeof PRODUCT_WITHDRAWAL_FORM_URL
  ]
}

export const getProductPdsUrl = (account: Account) => {
  if (isKiwiSaverOneAccount(account, account.isOwner)) {
    return PRODUCT_PDS_FILES[ProductSlug.FFKS]
  }
  if (isKiwiSaverTwoAccount(account, account.isOwner)) {
    return PRODUCT_PDS_FILES[ProductSlug.FF_TWO]
  }
  if (isOwnedManagedFundsAccount(account)) {
    return PRODUCT_PDS_FILES[ProductSlug.FFMF]
  }
  if (isKiwiSaverPlanAccount(account)) {
    return PRODUCT_PDS_FILES[ProductSlug.FFKP]
  }
  if (isInvestmentFundsAccount(account)) {
    return PRODUCT_PDS_FILES[ProductSlug.FFIF]
  }

  console.log(`Missing PDS url for ${account?.productExternalRef}`)
  return ''
}

export const DEFAULT_PRODUCT_SWITCH_URL =
  'https://fisherfunds.co.nz/investing/resources/switch-forms'

export const productSwitchIsPdfForm = (productSlug: ProductSlug) =>
  Object.keys(PRODUCT_SWITCH_REQUEST_URL).includes(productSlug)

export const productHasStrategies = (productSlug: ProductSlug) =>
  [
    ProductSlug.FFKS,
    ProductSlug.FF_TWO,
    ProductSlug.FFMF,
    ProductSlug.FF_LIFE,
  ].includes(productSlug)

export const getProductSwitchRequestUrl = (
  productSlug: ProductSlug | string
) => {
  return (
    PRODUCT_SWITCH_REQUEST_URL[
      productSlug as keyof typeof PRODUCT_SWITCH_REQUEST_URL
    ] || DEFAULT_PRODUCT_SWITCH_URL
  )
}

export const getAccountProductSlug = (account: Account): string => {
  if (isKiwiSaverPlanAccount(account)) {
    return ProductSlug.FFKP
  }
  if (isKiwiSaverTwoAccount(account, false)) {
    return ProductSlug.FF_TWO
  }
  if (isKiwisaverAccount(account, false)) {
    return ProductSlug.FFKS
  }
  if (isManagedFundProductAccount(account)) {
    return ProductSlug.FFMF
  }
  if (isInvestmentFundsAccount(account)) {
    return ProductSlug.FFIF
  }
  if (isPremiumServiceAccount(account)) {
    return ProductSlug.FF_PREMIUM
  }
  if (isLifeSaverAccount(account)) {
    return ProductSlug.FF_LIFE
  }
  if (isFuturePlanAccount(account)) {
    return ProductSlug.FF_FUTURE
  }
  if (isInvestmentSeries(account)) {
    return ProductSlug.FF_INV
  }

  console.error(`Missing ProductSlug for ${account.productName}`)
  return ''
}

export const BANK_DETAILS = {
  [KIWISAVER_ONE]: {
    bankAccount: '02-0500-0799760-01',
    debitLink:
      'https://assets.fisherfunds.co.nz/kiwisaver-direct-debit-authority-form',
  },
  [KIWISAVER_TWO]: {
    bankAccount: '02-0506-0083982-00',
    debitLink:
      'https://assets.fisherfunds.co.nz/fisher-funds-two-direct-debit-authority',
  },
  [MANAGED_FUNDS_TEL]: {
    bankAccount: '02-0500-0799760-000',
    debitLink:
      'https://assets.fisherfunds.co.nz/managed-funds-direct-debit-authority-form',
  },
  [MANAGED_FUNDS_APEX]: {
    bankAccount: '02-0500-0799760-000',
    debitLink:
      'https://assets.fisherfunds.co.nz/managed-funds-direct-debit-authority-form',
  },
  [PREMIUM_SERVICE_TEL]: {
    bankAccount: '02-0500-0799760-000',
    debitLink:
      'https://assets.fisherfunds.co.nz/premium-service-direct-debit-authority-form',
  },
  [PREMIUM_SERVICE_APEX]: {
    bankAccount: '02-0500-0799760-000',
    debitLink:
      'https://assets.fisherfunds.co.nz/premium-service-direct-debit-authority-form',
  },
  [INVESTMENT_SERIES_TEL]: {
    bankAccount: '02-0506-0038620-01',
    debitLink:
      'https://assets.fisherfunds.co.nz/investment-series-direct-debit-authority-form',
  },
  [INVESTMENT_SERIES_APEX]: {
    bankAccount: '02-0506-0038620-01',
    debitLink:
      'https://assets.fisherfunds.co.nz/investment-series-direct-debit-authority-form',
  },
  [LIFE_SAVER]: {
    bankAccount: '02-0506-0118495-000',
    debitLink:
      'https://assets.fisherfunds.co.nz/lifesaver-plan-direct-debit-authority-form',
  },
  [FUTUREPLAN]: {
    bankAccount: '02-0506-0038612-01',
    debitLink:
      'https://assets.fisherfunds.co.nz/futureplan-direct-debit-authority-form',
  },
  [KIWISAVER_PLAN]: {
    bankAccount: '12-3231-0516614-000',
    debitLink:
      'https://assets.fisherfunds.co.nz/kiwisaver-plan-direct-debit-form',
  },
  [INVESTMENT_FUNDS]: {
    bankAccount: '12-3244-0014517-000',
    debitLink:
      'https://assets.fisherfunds.co.nz/investment-funds-direct-debit-form',
  },
}

export const removeFisherFundsFromProductName = (productName: string) =>
  !!productName && productName.replace(/fisher funds/i, '').trim()

export const getIPQLink = (account: Account) => {
  const slug = isOwnedManagedFundsAccount(account)
    ? ProductSlug.FFMF
    : isKiwiSaverOneAccount(account, account.isOwner)
    ? ProductSlug.FFKS
    : isKiwiSaverTwoAccount(account, account.isOwner)
    ? ProductSlug.FF_TWO
    : null

  if (!slug) {
    return null
  }

  return `${process.env.REACT_APP_WEBSITE_URL}/${slug}/ipq`
}
