import 'rxjs'

import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'

import * as actions from './atomic-notifications.actions'
import { AtomicNotificationsState } from './atomic-notifications.model'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

type LightStore = { getState: Function; dispatch: Function }

export const getAtomicJwtEpic: Epic<
  actions.AtomicNotificationsActions,
  AtomicNotificationsState
> = (
  action$: ActionsObservable<actions.AtomicJwtRequestActionType>,
  store: LightStore
) =>
  action$.ofType(actions.actionTypes.ATOMIC_JWT_REQUEST).switchMap((action) =>
    ajax
      .get(
        `${ffmAppServerUrl}/api/v1/client/${action.payload.fscClientId}/atomic/token`,
        {
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
        }
      )
      .map((response) =>
        actions.AtomicJwtRequestSuccessAction(response.response.jwt)
      )
      .catch((err) =>
        Observable.of(
          actions.AtomicJwtRequestFailureAction('JWT request failed')
        )
      )
  )
