import { Badge, IconButton } from '@material-ui/core'
import { updateAtomicUnseenCards, useAtomic } from '../../hooks/useAtomic'
import Modal from '../modal/Modal'
import { NotificationsNoneOutlined } from '@material-ui/icons'
import './NotificationBell.scss'
import { connect, Dispatch } from 'react-redux'
import { AppState } from '../../redux/app-state'
import { calculateAccountTotal } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import { useEffect } from 'react'
import { AuthorisationActions } from '../../redux/authorisation/authorisation.actions'
import { bindActionCreators } from 'redux'
import { SetNumUnseenAtomicCardsRequestAction } from '../../redux/atomic-notifications/atomic-notifications.actions'

interface NotificationBellProps {
  notificationOpen: boolean
  userDataLoaded: boolean
  handleBellClick: () => void
  handleBellClose: () => void
  total?: number
  accountErrors?: Account[]
  numUnseenAtomicCards?: number
  setUnseenCards?: typeof SetNumUnseenAtomicCardsRequestAction
}

export function NotificationBell(props: NotificationBellProps) {
  const {
    notificationOpen,
    userDataLoaded,
    handleBellClick,
    handleBellClose,
    total,
    accountErrors,
    numUnseenAtomicCards,
    setUnseenCards,
  } = props
  const [atomicContainer] = useAtomic(total, accountErrors)

  useEffect(() => {
    updateAtomicUnseenCards(setUnseenCards)
  }, [notificationOpen, setUnseenCards])

  return (
    <>
      <Badge badgeContent={numUnseenAtomicCards} color="error" overlap="circle">
        <IconButton
          disabled={!userDataLoaded}
          onClick={handleBellClick}
          className="bell-button"
        >
          <NotificationsNoneOutlined />
        </IconButton>
      </Badge>
      {notificationOpen && (
        <Modal
          title="Your notifications"
          className="notifications-modal"
          open
          onClose={handleBellClose}
        >
          {atomicContainer}
        </Modal>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  const { total } = calculateAccountTotal(state.accounts.accounts)

  return {
    total,
    accountErrors: state.accounts.accountErrors,
    numUnseenAtomicCards: state.atomicNotifications.numUnseenAtomicCards,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AuthorisationActions>) => ({
  setUnseenCards: bindActionCreators(
    SetNumUnseenAtomicCardsRequestAction,
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(NotificationBell)
