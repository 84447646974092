export { ProductCode } from 'shared'

// To avoid cyclic dependencies we separate product variables from product helpers.
export enum ProductSlug {
  FFKS = 'kiwisaver',
  FFKP = 'kiwisaver-plan',
  FFMF = 'managed-funds',
  FFIF = 'investment-funds',
  FF_TWO = 'fisher-funds-two',
  FF_FUTURE = 'futureplan',
  FF_PREMIUM = 'premium-service',
  FF_LIFE = 'lifesaver',
  FF_INV = 'investment-series',
  FF_IMA = 'ima',
}

export enum ProductType {
  FFKS = 'Fisher Funds KiwiSaver Scheme',
  FFMF = 'Fisher Funds Managed Funds',
  FF_TWO = 'Fisher Funds TWO KiwiSaver',
  FF_PREMIUM = 'Fisher Funds Premium Service',
  FF_LIFE = 'Fisher Funds Lifesaver Plan',
  FF_FUTURE = 'Fisher Funds FuturePlan', // Note: not exist in salesforce Product__c as of May 2022
  FF_INV = 'Fisher Funds Investment Series',
  FFIF = 'Fisher Funds Investment Funds',
  FFKP = 'Fisher Funds KiwiSaver Plan',
}

/* Account productExternalRef tell us the type of account.
 * These are set in the API based of the type of account.
 * productExternalRef of 01 and 02 are KiwiSaver types.
 * productExternalRef of 04 through 08 are Managed Funds types.
 * See `accounts.data.ts` in the api.
 */
//TEL Product External ref codes
export const KIWISAVER_ONE = '02'
export const MANAGED_FUNDS_TEL = '05' //TODO: Once migrated to apex, delete reference to TEL version.
export const INVESTMENT_SERIES_TEL = '04' //TODO: once migrated to apex, delete reference to TEL version.
export const PREMIUM_SERVICE_TEL = '06' //TODO: once migrated to apex, delete reference to TEL version
export const LIFE_SAVER = '07'
export const FUTUREPLAN = '08'

//Apex Product External ref codes
export const KIWISAVER_PLAN = 'FFKP'
export const INVESTMENT_FUNDS = 'FFIF'
export const MANAGED_FUNDS_APEX = 'FFMF'
export const KIWISAVER_TWO = 'FFTWO'
export const PREMIUM_SERVICE_APEX = 'FFPS'
export const INVESTMENT_SERIES_APEX = 'FFIS'

// Used for denoting products with the Api Bridge
export const ProductShortCodes: { [key: string]: string } = {
  [KIWISAVER_PLAN]: 'FFKP',
  [KIWISAVER_ONE]: 'FFKS',
  [KIWISAVER_TWO]: 'FFTWO',
  [MANAGED_FUNDS_TEL]: 'FFMF',
  [MANAGED_FUNDS_APEX]: 'FFMF',
  [INVESTMENT_FUNDS]: 'FFIF',
  [PREMIUM_SERVICE_TEL]: 'FFPS',
  [PREMIUM_SERVICE_APEX]: 'FFPS',
  [INVESTMENT_SERIES_TEL]: 'FFIS',
  [INVESTMENT_SERIES_APEX]: 'FFIS',
  [LIFE_SAVER]: 'FFLS',
  [FUTUREPLAN]: 'FFFP',
}

export const ProductDisplayOrder = Object.keys(ProductShortCodes)
