import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import WithdrawFundsIntro from './WithdrawFundsIntro'
import WithdrawFundsForm from './WithdrawFundsForm'
import WithdrawFundsConfirmation from './WithdrawFundsConfirmation'
import FFKPWithdrawFundsContent from './FFKPWithdrawFundsPDFContent'
import FFIFWithdrawFundsContent from './FFIFWithdrawFundsPDFContent'
import Modal from '../modal/Modal'
import {
  hasWriteAuthorisation,
  isInvestmentFundsAccount,
  isKiwiSaverPlanAccount,
  isKiwiSaverTwoAccount,
} from '../../common/accounts-helper'
import { isAdult } from '../../common/user-helper'
import { AppState } from '../../redux/app-state'
import { UserState } from '../../redux/user/user.model'
import { Account } from '../../redux/accounts/accounts.model'
import getWithdrawApi from '../../api/withdraw'

export interface WithdrawModalProps {
  account: Account
  user: UserState
  onClose: () => void
  authToken: string
  userId: string
}

enum WITHDRAW_MODAL_STEPS {
  INTRO,
  FORM,
  CONFIRMATION,
  PDF_WITHDRAWAL,
}

export enum WithdrawalTypeEnum {
  Full = 'Full',
  Partial = 'Partial',
}

export type WithdrawalFormFields = {
  product: string
  accountId: string
  withdrawalType: WithdrawalTypeEnum
  useExistingBankAccount: boolean
  withdrawalAmount: number | null
  bankAccountName: string | null
  bankAccountNumber: string | null
}

const WithdrawFundsModal = ({
  account,
  user,
  onClose,
  authToken,
  userId,
}: WithdrawModalProps) => {
  const initialStep =
    isKiwiSaverPlanAccount(account) || isKiwiSaverTwoAccount(account, false)
      ? WITHDRAW_MODAL_STEPS.INTRO
      : WITHDRAW_MODAL_STEPS.FORM
  const [step, setStep] = useState<number>(initialStep)
  const [formFields, setFormFields] = useState<WithdrawalFormFields>()
  const [isLoading, setIsLoading] = useState(false)

  const isOnlineWithdrawalFormEnabled =
    process.env.REACT_APP_FEATURE_ENABLE_ONLINE_WITHDRAW_MODAL === 'true'

  const showPdfModal =
    !isOnlineWithdrawalFormEnabled ||
    (isInvestmentFundsAccount(account) &&
      (!isAdult(user.birthDate) || !hasWriteAuthorisation(account)))

  const getBankAccountDetails = (bankAccountNumber?: string) => {
    if (!bankAccountNumber) {
      return null
    }

    const [
      bankNumber,
      branchNumber,
      accountNumber,
      accountSuffix,
    ] = bankAccountNumber.split('-')
    return {
      bankNumber,
      branchNumber,
      accountNumber,
      accountSuffix,
    }
  }

  const handleSubmit = (values: WithdrawalFormFields) => {
    setIsLoading(true)
    getWithdrawApi(authToken)
      .postWithdraw({
        accountNumber: account.accountNumber,
        fscClientId: userId,
        data: {
          useBankAccountOnFile: values.useExistingBankAccount,
          type: values.withdrawalType,
          newBankAccount: values.bankAccountNumber
            ? {
                name: values.bankAccountName,
                ...getBankAccountDetails(values.bankAccountNumber),
              }
            : null,
          totalWithdrawalAmount: values.withdrawalAmount ?? null,
        },
      })
      .then(() => {
        setFormFields(values)
        handleStepNext()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    //If we haven't enabled the online withdrawals yet, or FFIF is under 18 || not an authorised actor we will show PDF.
    if (showPdfModal) {
      setStep(WITHDRAW_MODAL_STEPS.PDF_WITHDRAWAL)
    }
  }, [account, showPdfModal]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleStepNext = () => {
    switch (step) {
      case WITHDRAW_MODAL_STEPS.INTRO:
        return setStep(WITHDRAW_MODAL_STEPS.FORM)
      case WITHDRAW_MODAL_STEPS.FORM:
        return setStep(WITHDRAW_MODAL_STEPS.CONFIRMATION)
    }
  }
  const handleStepPrevious = () => {
    return setStep(WITHDRAW_MODAL_STEPS.INTRO)
  }

  const renderStep = () => {
    switch (step) {
      case WITHDRAW_MODAL_STEPS.FORM:
        return (
          <WithdrawFundsForm
            account={account}
            handleSubmit={handleSubmit}
            handleBack={isKiwiSaverPlanAccount(account) && handleStepPrevious}
            isDisable={isLoading}
          />
        )

      case WITHDRAW_MODAL_STEPS.CONFIRMATION:
        return (
          <WithdrawFundsConfirmation
            amount={formFields.withdrawalAmount}
            accountName={formFields.bankAccountName}
            accountNumber={formFields.bankAccountNumber}
            onDone={onClose}
          />
        )

      case WITHDRAW_MODAL_STEPS.INTRO:
      default:
        return (
          <WithdrawFundsIntro account={account} onSubmit={handleStepNext} />
        )
    }
  }

  const renderPDFModal = () => {
    if (isKiwiSaverPlanAccount(account)) {
      return <FFKPWithdrawFundsContent onClose={onClose} />
    }
    return <FFIFWithdrawFundsContent onClose={onClose} />
  }

  return (
    <Modal
      open
      title="Withdraw funds"
      subtitle={account.accountNumber}
      onClose={onClose}
      className="withdraw-funds-modal"
    >
      {showPdfModal ? renderPDFModal() : renderStep()}
    </Modal>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    authToken: state.authorisation.authorisationToken,
    userId: state.user.userid,
  }
}

export default connect(mapStateToProps)(WithdrawFundsModal)
