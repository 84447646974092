import { AnyAction, Reducer } from 'redux'

import { actionTypes } from './atomic-notifications.actions'
import { AtomicNotificationsState } from './atomic-notifications.model'
import { getDefaultState, setFailState } from '../state/state.model'

export const initialState: AtomicNotificationsState = {
  numUnseenAtomicCards: 0,
  isAtomicInitialised: false,
  atomicJwt: null,
  ...getDefaultState(),
}

export const atomicNotificationsReducer: Reducer<AtomicNotificationsState> = (
  state: AtomicNotificationsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.SET_NUM_UNSEEN_ATOMIC_CARDS_REQUEST:
      return {
        ...state,
        numUnseenAtomicCards: action.payload,
      }
    case actionTypes.SET_IS_ATOMIC_INITIALISED_REQUEST:
      return {
        ...state,
        isAtomicInitialised: action.payload,
      }
    case actionTypes.ATOMIC_JWT_REQUEST:
      return {
        ...state,
        hasError: false,
        errorMessage: null,
      }
    case actionTypes.ATOMIC_JWT_REQUEST_SUCCESS:
      return {
        ...state,
        atomicJwt: action.payload,
      }
    case actionTypes.ATOMIC_JWT_REQUEST_FAILURE:
      return {
        ...state,
        ...setFailState(action.payload),
      }
    default:
      return state
  }
}
