import subMonths from 'date-fns/subMonths'
import numeral from 'numeral'
import { AccountFund } from '../redux/account-funds/account-funds.model'
import {
  Fund,
  StrategyFundAllocation,
} from '../components/switch-contentful-modal/SwitchContentfulModal.types'
import { FUNDS_TEL_CODES } from 'shared'
export {
  APEX_FUNDS_CODES,
  FUNDS_TEL_CODES,
  APEX_MIX_MANDATE_SHORTNAME,
  getIPQTargetClientSummary,
} from 'shared'

export const isMoreThan5Years = (inceptionDate: Date) =>
  subMonths(Date.now(), 5 * 12) > inceptionDate

/**
 * Funds don't have Salesforce code by default, but sometimes we want to link the fund to
 * their mapped strategy (the strategy only has one fund) section on product investment options page.
 * This function returns the mapped strategy's Salesforce code which is used as anchor
 * on product investment options page.
 * @param telCode
 */
export const getStrategyAnchorForMixedAssetFund = (telCode: string) => {
  switch (telCode) {
    case FUNDS_TEL_CODES.KS_CONSERVATIVE_FUND:
    case FUNDS_TEL_CODES.MF_CONSERVATIVE_FUND:
      return 'Conservative'
    case FUNDS_TEL_CODES.KS_GROWTH_FUND:
    case FUNDS_TEL_CODES.MF_GROWTH_FUND:
      return 'Growth'
    default:
      return null
  }
}

/**
 * The "No Strategy" label is just in case we ever want to scroll/link to a
 * fund with no strategy so URL hash fails nicely.
 * Not happening currently AFAIK.
 */
export const getSalesforceCode = (telCode: string) =>
  getStrategyAnchorForMixedAssetFund(telCode) || 'No Strategy'

type PatialFund = Pick<AccountFund, 'assetCode' | 'dollarBalance'>

export const hasFundBalance = (fundCode: string, funds: PatialFund[]) =>
  (funds || [])
    .filter((f) => f.assetCode === fundCode)
    .some((x) => x.dollarBalance > 0)

export const getFundPath = () => `${process.env.REACT_APP_WEBSITE_URL}/`

export const isFundAvailable = (fund: Fund, accountFunds: AccountFund[]) =>
  !fund.isSuspended &&
  (!fund.legacy || hasFundBalance(fund.telCode, accountFunds))

export type Allocation = Pick<
  StrategyFundAllocation,
  'allocationPercentage'
> & {
  fund: {
    name: string
    shortName: string
  }
}

export const calculateFundsAllocations = (funds: AccountFund[]) => {
  const accountTotal = funds.reduce(
    (total, fund) =>
      numeral(total)
        .add(fund.dollarBalance)
        .subtract(fund.pieTaxLiability)
        .value(),
    0
  )
  return funds
    .sort((a, b) => a.fundDisplayName.localeCompare(b.fundDisplayName))
    .map((fund) => {
      const percentageFormatted = numeral(fund.dollarBalance)
        .subtract(fund.pieTaxLiability)
        .divide(accountTotal)
        .multiply(100)
        .format('0,0.00')

      return {
        allocationPercentage: Number(percentageFormatted),
        fund: {
          name: fund.fundDisplayName,
          shortName: fund.fundDisplayName,
        },
      } as Allocation
    })
}

export const mapAccountDetailsFundsToAllocations = (
  funds: any[] = []
): Allocation[] =>
  funds
    .sort((a, b) => a.fundName.localeCompare(b.fundName))
    .map((f) => ({
      allocationPercentage: (f.investmentPercentageSplit || 0).toString(),
      fund: {
        name: f.fundName,
        shortName: f.fundShortName,
      },
    }))
